import React from "react";

import Layout from "../components/layouts/Layout";
import SEO from "../components/SEO";

const NotFoundPage = () => (
	<Layout>
		<SEO title={"Jeti | Not found"}/>
		<div className={"container mx-auto px-10 md:px-0"}>
			<div className={"overflow-hidden flex flex-col justify-center h-screen"}>
				<div className={"text-secondary leading-none tracking-tight text-5xl font-bold"}>
					Page not found!
				</div>
				<div className={"text-black font-medium mt-5"}>
					Sorry about that.&nbsp;
					<a href={"/"} className={"text-primary"}>
						Return to our homepage
					</a>
					&nbsp;to get back on track.
				</div>
			</div>
		</div>
	</Layout>
);

export default NotFoundPage;
